<template>
	<div>
		<CCard class="p-2">

			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow style="color: #1352a1;">

						<CCol lg="6">
							<h5 v-if="!editMode">New Incident </h5>
							<h5 v-else>Update Incident</h5>
						</CCol>
						<CCol lg="6">
							<div style="text-align: right">
								<CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()"><i class="fa fa-close"></i> Cancel</CButton>
								<CButton class="px-3 mr-1" size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
							</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
					<CCard class="pl-3 pr-3">
						<CRow class="mt-3 mb-2">
							<CCol lg="12">
								<h5>Incident Details</h5>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="6">
								<div class="form-group">
									<label>Class <span class="text-danger">*</span></label>
									<v-select label="setting_name" :options="[
										{
											setting_name: 'Equipment',
											id: 'equipment'
										},
										{
											setting_name: 'Vehicle',
											id: 'vehicle'
										}
									]" :reduce="item => item.id" v-model="dataParams.class_type"
										placeholder="Select class">
									</v-select>
								</div>
							</CCol>
							<CCol lg="6">
								<div class="form-group" v-if="dataParams.class_type == 'vehicle'">
									<label>Plate no. <span class="text-danger">*</span></label>
									<v-select label="plate_no" :options="vehicleList.data" :reduce="item => item.id"
										v-model="dataParams.vehicle_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.vehicle_id" v-bind="attributes"
												v-on="events" />
										</template>
									</v-select>
								</div>
								<div class="form-group" v-if="dataParams.class_type == 'equipment'">
									<label>Equipment Asset No. <span class="text-danger">*</span></label>
									<v-select label="asset_no" :options="equipmentList.data" :reduce="item => item.id"
										v-model="dataParams.equipment_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.equipment_id"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<div class="form-group">
									<label class="mb-1"> Location of Incident <span class="text-danger"> *</span></label>
									<v-select label="setting_name" :options="originList.data" :reduce="item => item.id"
										v-model="dataParams.current_location_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.current_location_id"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="12">

							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<CInput label="Type" v-model="dataParams.type" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<CInput label="Person Involved" placeholder="" v-model="dataParams.persons_involved"
									type="text" />
							</CCol>
						</CRow>

						<CRow>
							<CCol lg="6">
								<div class="form-group">
									<label for="exampleInputEmail1">Incident Date & Time <span
											class="text-danger">*</span></label>
									<Datetime format="MM-DD-YYYY H:i" v-model="dataParams.incident_date" :required="true">
									</Datetime>

								</div>
							</CCol>
							<CCol lg="6">
								<label>Reported By <span class="text-danger">*</span></label>
								<CInput required v-model="dataParams.report_by" placeholder="" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<CTextarea label="Description" v-model="dataParams.description" placeholder="" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<CTextarea label="Remarks" v-model="dataParams.remarks" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12" class="mb-4">
								<label>Status<span class="text-danger"> *</span></label>
								<v-select label="text" :options="['Open', 'Closed']" v-model="dataParams.status">
									<template #search="{ attributes, events }">
										<input class="vs__search" :required="!dataParams.status" v-bind="attributes"
											v-on="events" />
									</template>
								</v-select>
							</CCol>
						</CRow>
					</CCard>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import Datetime from 'vuejs-datetimepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';
import NewCreate from './create_trucking_form'

export default {
	mounted() {


		if (this.$route.params.id != undefined) {
			this.editMode = true;
		}
		if (this.$route.params.id) {
			this.getData();
		}

		// if(this.$route.params.id) {
		//     this.editMode = true;
		// }
		this.getVehicle();
		this.getOrigin();
		// this.getData();
		this.getEquipment();
		// this.getDataEquipment();
		// this.getDataVehicle();

	},
	data() {
		return {
			current_tab: "All",
			count: 0,
			title: '',
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListAll: {
				data: []
			},

			depotName: "",
			dataParams: {
				vehicle_id: "",
				equipment_id: "",
				persons_involved: "",
				current_location_id: "",
				type: "",
				incident_date: "",
				report_by: "",
				description: "",
				remarks: "",
				class_type: "vehicle",
				status:'Open',
			},

			currentPageAll: 1,
			currentPageVehicle: 1,
			currentPageEquipment: 1,
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,

			depotList: {
				data: []
			},
			personnelTypeList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			originList: {
				data: []
			},
			customerList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			// equipmentCurrentLocationList: {
			// 	data: []
			// },
			moment
		}
	},
	name: '',
	components: { Search, Datepicker, Datetime, vSelect, NewCreate },
	methods: {

		updatePageAll(data) {
			this.currentPageAll = data;
			this.getDataAll();
		},
		updatePageVehicle(data) {
			this.currentPageVehicle = data;
			this.getDataVehicle()
		},
		updatePageEquipment(data) {
			this.currentPageEquipment = data;
			this.getDataEquipment();
		},

		toggleModal() {
			this.formModal = !this.formModal;
			this.title = "Add Incident"
		},
		backToTable() {
			this.$router.push('/main/incident')
		},

		getData() {
			this.$showLoading(true)

			axios.get(config.api_path + "/incident/" + this.$route.params.id)
				.then(response => {
					this.$showLoading(false)
					this.dataParams.incident_date = moment.unix(this.dataParams.incident_date).format("MM/DD/YYYY");
					this.dataParams = response.data.data;
				})
				.catch(err => {
					this.$showLoading(false)
				})

		},


		getOrigin() {

			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'origin'
				}
			})
				.then(response => {
					this.originList = response.data;
				})

		},


		getVehicle() {
			axios.get(config.api_path + '/reference/vehicle-list', {
				params: {
				}
			})
				.then(response => {
					this.vehicleList = response.data;
				})
		},

		getEquipment() {

			axios.get(config.api_path + '/reference/equipment-list')
				.then(response => {
					this.equipmentList = response.data;
					this.equipmentList.data = this.equipmentList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},
		submit() {
			var ax = {};
			if (this.dataParams.equipment_id == "") {
				this.dataParams.equipment_id = null;
			}
			if (this.dataParams.vehicle_id == "") {
				this.dataParams.vehicle_id = null;
			}
			if (this.dataParams.incident_date == "") {
				this.dataParams.incident_date = null;
			}
			else {
				this.dataParams.incident_date = moment(this.dataParams.incident_date, "MM/DD/YYYY H:mm").valueOf() / 1000;
				// this.dataParams.incident_date = moment(this.dataParams.incident_date).startOf('day').unix();
			}

			if (this.editMode) {
				ax = axios.put(config.api_path + "/incident/" + this.selectedRow.id, this.dataParams)
			}
			else {
				ax = axios.post(config.api_path + "/incident", this.dataParams)
			}
			ax.then(response => {
				var text = 'Incident successfully added!';
				if (this.editMode) {
					text = 'Incident successfully updated!';
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
				this.formModal = false
				this.editMode = false;
				this.dataParams = {
					vehicle_id: "",
					equipment_id: "",
					persons_involved: "",
					current_location_id: "",
					type: "",
					incident_date: "",
					report_by: "",
					description: "",
					remarks: "",
					class_type: "vehicle"
				}
				this.isLoading = false;
				this.$router.push('/main/view_incident/' + response.data.data.id)
			})
				.catch(e => {
					this.isLoading = false;
				})
		},
	}
}
</script>
    